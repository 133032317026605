/* eslint-disable react/no-array-index-key */

import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import { IContractPdfPropsServiceOrder } from '../types';
import { serviceOrderPdfStyle as styles } from '../style/style';
import { ClientAndVehicleInformation } from './ClientAndVehicleInformation';
import qrcodeWhatsapp from '../../../assets/qrcode-atendimento-ao-cliente.png';

const CancelContractPdf: React.FC<IContractPdfPropsServiceOrder> = ({
  vehicle,
  client,
  serviceOrder,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.table}>
          {/* Cabeçalho */}
          <View style={styles.section}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, { width: '35%' }]}>
                <Text style={[styles.tableCell, styles.grayBackground]}>
                  ORDEM DE SERVIÇO
                </Text>
              </View>
              <View style={[styles.tableCol, { width: '35%' }]}>
                <Text style={styles.tableCell}>Retirada de equipamento</Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  { width: '30%', ...styles.noBorderRight },
                ]}
              >
                <Text style={styles.tableCell}>TEL: 3900-8533</Text>
                <Text style={styles.tableCell}>0800 080 1110</Text>
              </View>
            </View>
          </View>

          {/* Informações do Cliente e Veículo */}
          <ClientAndVehicleInformation
            client={client}
            vehicle={vehicle}
            showAssociation
          />

          {/* Local da Instalação, OBS, QRCODE e Funcionamento */}
          <View style={styles.section}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, { width: '25%' }]}>
                <Text style={styles.centeredText}>LOCAL DA INSTALAÇÃO</Text>
              </View>

              <View style={[styles.tableCol, { width: '25%' }]}>
                <Text style={styles.centeredText}>OBSERVAÇÃO</Text>
                <Text style={styles.tableCellLeft}>{'\n'.repeat(3)}</Text>
              </View>
              <View style={[styles.tableCol, { width: '25%' }]}>
                <View
                  style={{ justifyContent: 'center', alignItems: 'center' }}
                >
                  <Text style={styles.centeredText}>WHATSAPP</Text>
                  <Image src={qrcodeWhatsapp} style={styles.image} />
                </View>
              </View>
              <View
                style={[
                  styles.tableCol,
                  { width: '25%', ...styles.noBorderRight },
                ]}
              >
                <Text style={[styles.centeredText, { marginBottom: 5 }]}>
                  FUNCIONAMENTO{'\n'}
                  Atendimento administrativo:{'\n'}
                  Segunda-Quinta:{'\n'}08:00-18:00
                </Text>
                <Text style={styles.centeredText}>
                  Sexta-feira:{'\n'} 08:00-17:00
                </Text>
              </View>
            </View>
          </View>

          {/* Local Atendimento e Técnico */}
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, { width: '50%' }]}>
              <Text style={styles.tableCellLeft}>
                Local Atendimento: {serviceOrder?.serviceLocation}
              </Text>
            </View>

            <View
              style={[
                styles.tableCol,
                {
                  width: '25%',
                },
              ]}
            >
              <Text style={styles.tableCellLeft}>
                Técnico: {serviceOrder?.installer}
              </Text>
            </View>
            <View
              style={[
                styles.tableCol,
                { width: '25%', ...styles.noBorderRight },
              ]}
            >
              <Text style={styles.tableCellLeft}>
                ID/IMEI: {vehicle?.device?.imei}
              </Text>
            </View>
          </View>

          {/* Acessórios e Status */}
          <View style={styles.section}>
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCol,
                  { width: '100%', ...styles.noBorderRight },
                ]}
              >
                <Text style={[styles.tableCell, styles.grayBackground]}>
                  ACESSÓRIOS E STATUS
                </Text>
              </View>
            </View>
            {[
              [
                'Alarme',
                'OK ( ) DF ( )',
                'Chave Comando de Setas',
                'OK ( ) DF ( )',
                'Pisca Alerta',
                'OK ( ) DF ( )',
                'Computador de Bordo',
                'OK ( ) DF ( )',
              ],
              [
                'Vidro Elétrico',
                'OK ( ) DF ( )',
                'Ar Condicionado',
                'OK ( ) DF ( )',
                'Som/Rádio/CD/DVD',
                'OK ( ) DF ( )',
                'Luz do Sistema de Freio ABS',
                'OK ( ) DF ( )',
              ],
              [
                'Trava Elétrica',
                'OK ( ) DF ( )',
                'Ar Quente / Ar Frio',
                'OK ( ) DF ( )',
                'Comando do Limpador de Para-brisa',
                'OK ( ) DF ( )',
                'Setas Direita / Esquerda',
                'OK ( ) DF ( )',
              ],
              [
                'Retrovisor Elétrico / Manual',
                'OK ( ) DF ( )',
                'Lanternas Dianteiras e Traseiras',
                'OK ( ) DF ( )',
                'Luz de Cortesia e Porta Malas',
                'OK ( ) DF ( )',
                'Luz de Freio / Brake Light',
                'OK ( ) DF ( )',
              ],
              [
                'Tampa de Porta Luvas',
                'OK ( ) DF ( )',
                'Farol Baixo Esquerdo / Direito',
                'OK ( ) DF ( )',
                'Desembaçador Traseiro / Espelhos',
                'OK ( ) DF ( )',
                'Luz do Sistema de Air Bag',
                'OK ( ) DF ( )',
              ],
              [
                'Teto Solar Elétrico/Manual',
                'OK ( ) DF ( )',
                'Farol Alto Esquerdo / Direito',
                'OK ( ) DF ( )',
                'Limpador de Para-brisa Diant./Tras./Faróis',
                'OK ( ) DF ( )',
                'Luzes de Anomalia / Iluminação do Painel',
                'OK ( ) DF ( )',
              ],
              [
                'Buzina / Sirene',
                'OK ( ) DF ( )',
                'Luz de Ré',
                'OK ( ) DF ( )',
                'Freio de Estacionamento',
                'OK ( ) DF ( )',
                'Outros',
                'OK ( ) DF ( )',
              ],
            ].map((row, index) => (
              <View style={styles.tableRow} key={index}>
                {row.map((cell, cellIndex) => (
                  <View
                    style={[
                      styles.tableCol,
                      { flex: cellIndex % 2 === 0 ? 2 : 1.5 }, // Aumentar largura das colunas de status
                      cellIndex === row.length - 1 ? styles.noBorderRight : {},
                    ]}
                    key={cellIndex}
                  >
                    <Text style={styles.tableCell}>{cell}</Text>
                  </View>
                ))}
              </View>
            ))}
          </View>

          {/* Observações Finais */}
          <View style={styles.section}>
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCol,
                  { width: '100%', ...styles.noBorderRight },
                ]}
              >
                <Text style={styles.tableCellLeft}>OBSERVAÇÃO:</Text>
                <Text style={styles.tableCellLeft}>{'\n'.repeat(5)}</Text>{' '}
                {/* 5 linhas em branco */}
              </View>
            </View>
          </View>

          {/* Declaração e Assinatura */}
          <View style={styles.section}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, { width: '50%' }]}>
                <Text style={styles.tableCellLeft}>
                  Nome do Responsável / CPF
                </Text>
                <View style={styles.signatureLine} />
              </View>
              <View
                style={[
                  styles.tableCol,
                  { width: '50%', ...styles.noBorderRight },
                ]}
              >
                <Text style={styles.tableCellLeft}>
                  Assinatura do Responsável
                </Text>
                <View style={styles.signatureLine} />
              </View>
            </View>
          </View>

          <View style={styles.section}>
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCol,
                  { width: '100%', ...styles.noBorderRight },
                ]}
              >
                <Text style={[styles.tableCellLeft, styles.grayBackground]}>
                  Declaro estar ciente do serviço realizado no veículo e que em
                  caso de dúvida fui orientado a ligar para a Central da
                  Prestadora de Serviços. Acompanhei a vistoria feita antes e
                  depois da execução do serviço estando em pleno acordo que não
                  há qualquer problema com o veículo. Estou de acordo com as
                  informações assinaladas e descritas neste formulário, não
                  tendo nada a reclamar.
                </Text>
              </View>
            </View>
          </View>

          {/* Local e Data / Assinatura de Conclusão */}
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, { width: '50%' }]}>
              <Text style={styles.tableCellLeft}>Local e Data:</Text>
              <View style={styles.fullWidthLine} />
            </View>
            <View
              style={[
                styles.tableCol,
                { width: '50%', ...styles.noBorderRight },
              ]}
            >
              <Text style={styles.tableCellLeft}>Assinatura de Conclusão:</Text>
              <View style={styles.fullWidthLine} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CancelContractPdf;
