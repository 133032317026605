import { StyleSheet } from '@react-pdf/renderer';

export const serviceOrderPdfStyle = StyleSheet.create({
  page: {
    padding: 10,
    fontSize: 9,
    fontFamily: 'Helvetica',
  },
  section: {
    marginBottom: 5,
  },
  table: {
    borderWidth: 0.5,
    borderStyle: 'solid',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 0.5,
    borderColor: '#000',
  },
  tableCol: {
    borderRightWidth: 0.5,
    borderColor: '#000',
    padding: 3,
  },
  tableCell: {
    textAlign: 'center',
  },
  tableCellLeft: {
    textAlign: 'left',
  },
  logo: {
    width: 60,
    height: 30,
  },
  image: {
    width: 60,
    height: 50,
  },
  fullWidth: {
    width: '100%',
  },
  noBorderRight: {
    borderRightWidth: 0,
  },
  signatureLine: {
    borderBottomWidth: 0.5,
    borderColor: '#000',
    marginTop: 5,
    height: 10,
  },
  fullWidthLine: {
    borderBottomWidth: 0.5,
    borderColor: '#000',
    width: '100%',
    marginTop: 5,
    height: 10,
  },
  grayBackground: {
    backgroundColor: '#a7a7a7',
    padding: 3,
  },
  boldText: {
    fontWeight: 'bold',
  },
  smallText: {
    fontSize: 8,
  },
  centeredText: {
    textAlign: 'center',
  },
});
