import { Text, View } from '@react-pdf/renderer';
import { IVehicles } from 'pages/DeviceManagement/types';
import { serviceOrderPdfStyle as styles } from '../style/style';

interface IProps {
  client: IVehicles['client'];
  vehicle?: IVehicles;
  showAssociation?: boolean;
}

export const ClientAndVehicleInformation = ({
  client,
  vehicle,
  showAssociation = false,
}: IProps) => {
  return (
    <View style={styles.section}>
      <View style={styles.tableRow}>
        <View
          style={[styles.tableCol, { width: '100%', ...styles.noBorderRight }]}
        >
          <Text style={styles.tableCellLeft}>
            Nome / Razão Social: {client?.name}
          </Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, { width: '60%' }]}>
          <Text style={styles.tableCellLeft}>Endereço: {client?.street}</Text>
        </View>
        <View
          style={[styles.tableCol, { width: '40%', ...styles.noBorderRight }]}
        >
          <Text style={styles.tableCellLeft}>Bairro: {client?.street}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, { width: '30%' }]}>
          <Text style={styles.tableCellLeft}>Cidade: {client?.city}</Text>
        </View>
        <View style={[styles.tableCol, { width: '10%' }]}>
          <Text style={styles.tableCellLeft}>UF: {client?.uf}</Text>
        </View>
        <View style={[styles.tableCol, { width: '20%' }]}>
          <Text style={styles.tableCellLeft}>CEP: {client?.zipCode}</Text>
        </View>
        <View
          style={[styles.tableCol, { width: '20%', ...styles.noBorderRight }]}
        >
          <Text style={styles.tableCellLeft}>Telefone: {client?.phone}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, { width: '30%' }]}>
          <Text style={styles.tableCellLeft}>
            Veículo: {vehicle?.make} - {vehicle?.model}
          </Text>
        </View>
        <View style={[styles.tableCol, { width: '15%' }]}>
          <Text style={styles.tableCellLeft}>Ano: {vehicle?.modelYear}</Text>
        </View>
        <View style={[styles.tableCol, { width: '15%' }]}>
          <Text style={styles.tableCellLeft}>Cor: {vehicle?.color}</Text>
        </View>
        <View
          style={[styles.tableCol, { width: '20%', ...styles.noBorderRight }]}
        >
          <Text style={styles.tableCellLeft}>Placa: {vehicle?.plate}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, { width: '30%' }]}>
          <Text style={styles.tableCellLeft}>Chassi: {vehicle?.vin}</Text>
        </View>
        <View style={[styles.tableCol, { width: '30%' }]}>
          <Text style={styles.tableCellLeft}>Renavam: {vehicle?.renavam}</Text>
        </View>
        {showAssociation ? (
          <View
            style={[styles.tableCol, { width: '30%', ...styles.noBorderRight }]}
          >
            <Text style={styles.tableCellLeft}>Associação: {client?.name}</Text>
          </View>
        ) : (
          <View
            style={[styles.tableCol, { width: '30%', ...styles.noBorderRight }]}
          >
            <Text style={styles.tableCellLeft}>
              Cliente: F ({client?.clientType === 'J' ? '' : 'X'} ) J (
              {client?.clientType === 'J' ? 'X' : ''})
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};
