import React from 'react';
import {
  DatePicker as MuiDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
// import { makeStyles } from '@material-ui/core/styles';

import ptBR from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import { format, parseISO } from 'date-fns';
import { Controller } from 'react-hook-form';
import TextField from '../TextField/index.tsx';

export default function DatePicker({
  value = undefined,
  // onChange,
  label,
  name,
  // eslint-disable-next-line prettier/prettier
  handleChangeDate = date => {},
  // eslint-disable-next-line prettier/prettier
  handleChangeYear = () => {},
  error,
  control,
  rules,
  disabled = false,
  ...rest
}) {
  if (control) {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            field: { onChange, ref, value: formValue, ...formProps },
          }) => (
            <MuiDatePicker
              inputVariant="outlined"
              // PopoverProps={{ className: classes.datePicker }}
              // value={selectedDate}
              label={label}
              value={formValue ? parseISO(formValue) || null : null}
              onChange={date =>
                onChange && onChange(format(date, 'yyyy-MM-dd'))
              }
              // onChange={date => onChange(date)}
              onYearChange={date =>
                handleChangeYear && handleChangeYear(format(date, 'yyyy'))
              }
              // inputVariant="filled"
              format="dd/MM/yyyy"
              variant="outlined"
              allowKeyboardControl
              autoOk
              invalidDateMessage="Data inválida"
              error={!!error}
              helperText={error || ''}
              {...rest}
              {...formProps}
              disabled={disabled}
              TextFieldComponent={TextField}
            />
          )}
        />
      </MuiPickersUtilsProvider>
    );
  }
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <MuiDatePicker
        inputVariant="outlined"
        label={label}
        value={value ? parseISO(value) || null : null}
        onChange={date =>
          handleChangeDate && handleChangeDate(format(date, 'yyyy-MM-dd'))
        }
        onYearChange={date =>
          handleChangeYear && handleChangeYear(format(date, 'yyyy'))
        }
        format="dd/MM/yyyy"
        variant="inline"
        allowKeyboardControl
        autoOk
        invalidDateMessage="Data inválida"
        error={!!error}
        helperText={error || ''}
        {...rest}
        disabled={disabled}
        TextFieldComponent={TextField}
      />
    </MuiPickersUtilsProvider>
  );
}
