import { useCallback, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';
import {
  Button,
  MenuItem,
  Grid,
  makeStyles,
  CircularProgress,
  Typography,
  SvgIconTypeMap,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { blueGrey } from '@material-ui/core/colors';

import { EnhancedTable } from 'components/EnhancedTable/index.js';
import TextField from 'components/TextField';
import { CustomModal } from 'components/Modal/';

import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { masks } from 'utils/mask';
import { useAuth } from 'context/authProvider';
import { IVehicles } from 'pages/DeviceManagement/types';
import { Autocomplete } from '@material-ui/lab';
import { serviceOrderReasons, serviceOrderStatus } from '../utils/optionts';

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonProgress: {
    color: blueGrey[500],
    position: 'absolute',
  },
  buttonSuccess: {},
});
export type OnSearch = (
  column: string,
  payload: string[] | string,
) => void | Promise<void>;
interface ISearchModalProps {
  toggle: () => void;
  isOpen: boolean;
  title: string;
  vehicles: IVehicles[];
  searchOptions: {
    id: number;
    label: string;
    value: string;
  }[];
  tableActions: {
    id: number;
    title: string;
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    action: any;
  }[];
  onSearch: OnSearch;
  headCells: { [key: string]: any }[];
  dataCells: { [key: string]: any }[];
  loading: boolean;
  onRowClick: (row: any) => void;
  selectedRow: any[];
  selectAllRows: (rows: any) => void;
  searchForMoreThanOne?: boolean;
  height?: string | number;
}

export function ServiceOrderSearchModal({
  toggle,
  isOpen,
  title = '',
  searchOptions,
  tableActions,
  dataCells,
  headCells,
  vehicles,
  // eslint-disable-next-line prettier/prettier
  onSearch,
  loading = false,
  // eslint-disable-next-line prettier/prettier
  onRowClick,
  selectedRow = [],
  // eslint-disable-next-line prettier/prettier
  selectAllRows = () => {},
  searchForMoreThanOne = true,
  height,
}: ISearchModalProps) {
  const { user } = useAuth();

  const hasPermissionToSeeAllDevices = user.id === 16;

  const classes = useStyles();
  const [searchTerms, setSearchTerms] = useState(
    searchOptions.map(({ value }) => ({
      column: value,
      payload: '',
    }))[0],
  );

  const [filterOptions, setFilterOptions] = useState({
    finishedServiceOrders: true,
    unFinishedServiceOrders: true,
  });

  const filteredData = dataCells?.filter(data => {
    if (
      filterOptions.finishedServiceOrders &&
      filterOptions.unFinishedServiceOrders
    ) {
      return true;
    }

    if (filterOptions.finishedServiceOrders) {
      return data.finished;
    }

    if (filterOptions.unFinishedServiceOrders) {
      return !data.finished;
    }

    return true;
  });

  const buttonClassname = clsx({
    [classes.buttonSuccess]: !loading,
  });

  const renderSearchOptions = useMemo(
    () =>
      searchOptions.map(({ id, label, value }) => (
        // eslint-disable-next-line react/no-array-index-key
        <MenuItem key={id} value={value}>
          {label}
        </MenuItem>
      )),
    [searchOptions],
  );

  const handleChangeSearchOptions = useCallback(
    value => {
      setSearchTerms({ column: searchTerms.column, payload: value });
    },
    [searchTerms],
  );

  const onRequestSubmit = useCallback(() => {
    const { payload, column } = searchTerms;
    if (searchForMoreThanOne && searchTerms.column !== 'name') {
      const payloadArray = payload.split(/,| | ,| , |, /g).filter(p => p);
      const payloadForCallback =
        payloadArray.length > 1 ? payloadArray : payload;

      if (onSearch) {
        onSearch(column, payloadForCallback);
      }
    } else if (onSearch) {
      onSearch(column, payload);
    }
  }, [onSearch, searchForMoreThanOne, searchTerms]);

  const handleChangeSearchTerms = useCallback(
    e => {
      if (e.target.name === 'column') {
        setSearchTerms({ column: e.target.value, payload: '' });
      } else {
        switch (searchTerms.column) {
          case 'plate':
            masks.plateSearch(e);
            break;
          case 'vin':
            // masks.vinSearch(e);
            break;
          default:
            break;
        }
        setSearchTerms({ ...searchTerms, [e.target.name]: e.target.value });
      }
    },
    [searchTerms],
  );
  const renderOptions = useCallback(() => {
    switch (searchTerms.column) {
      case 'reason':
        return serviceOrderReasons;
      case 'status':
        return serviceOrderStatus;
      case 'vehicleId':
        return vehicles.map(vehicle => {
          return { label: vehicle.plate, value: vehicle?.id?.toString() };
        });
      default:
        return [];
    }
  }, [searchTerms.column, vehicles]);

  return (
    <CustomModal height={height} isOpen={isOpen} handleClose={toggle}>
      <>
        <div style={{ marginBottom: 25 }}>
          {title}
          {hasPermissionToSeeAllDevices && (
            <Typography
              variant="button"
              display="block"
              style={{ fontSize: 10 }}
            >
              *Você tem permissão para pesquisar dispositivos de todas as
              empresas
            </Typography>
          )}
        </div>
        <div style={{ height: 450, minWidth: 600 }}>
          <Grid container spacing={2}>
            <Grid item md={3} sm={12}>
              <TextField
                label="Buscar por:"
                name="column"
                value={searchTerms.column}
                onChange={handleChangeSearchTerms}
                select
                variant="outlined"
                size="small"
                fullWidth
              >
                {renderSearchOptions}
              </TextField>
            </Grid>

            <Grid item md={7} sm={10}>
              <Autocomplete
                id="combo-box-demo"
                onInputChange={(event, newInputValue) => {
                  const selectedOption = renderOptions().find(
                    option => option.label === newInputValue,
                  );
                  handleChangeSearchOptions(selectedOption?.value);
                }}
                disabled={searchTerms.column === 'listAll'}
                options={renderOptions()}
                getOptionLabel={option => option.label}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Escreva aqui:"
                    name="payload"
                    size="small"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={2} className={classes.wrapper}>
              <Button
                onClick={() => onRequestSubmit()}
                variant="contained"
                color="primary"
                size="large"
                type="button"
                aria-label="delete"
                disabled={loading}
                className={buttonClassname}
              >
                <Search />
              </Button>
              {loading && (
                <>
                  <div
                    style={{
                      position: 'absolute',
                      left: 30,
                      top: 15,
                    }}
                  >
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  </div>
                </>
              )}
            </Grid>
          </Grid>
          <Box style={{ display: 'flex', gap: 5 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterOptions.finishedServiceOrders}
                  onChange={() =>
                    setFilterOptions(prev => ({
                      ...prev,
                      finishedServiceOrders:
                        !filterOptions.finishedServiceOrders,
                    }))
                  }
                  name="Finalizadas"
                  color="primary"
                  size="small"
                />
              }
              label={<Typography>Finalizadas</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterOptions.unFinishedServiceOrders}
                  onChange={() =>
                    setFilterOptions(prev => ({
                      ...prev,
                      unFinishedServiceOrders:
                        !filterOptions.unFinishedServiceOrders,
                    }))
                  }
                  name="Não finalizadas"
                  color="primary"
                  size="small"
                />
              }
              label={<Typography>Não finalizadas</Typography>}
            />
          </Box>
          <EnhancedTable
            toolbar
            dataCells={filteredData || []}
            headCells={headCells}
            tableActions={tableActions}
            loading={loading}
            onRowClick={onRowClick}
            selectAllRows={selectAllRows}
            selectedRow={selectedRow}
            maxHeightTable={500}
            minHeightTable={370}
            minWidthTable={800}
            defaultRowsPerPage={10}
            rowsPerPageOptions={[10, 25, 50, 100]}
            maxWidthTable={undefined}
          />
        </div>
      </>
    </CustomModal>
  );
}
