/* eslint-disable react/no-array-index-key */

import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import { IContractPdfPropsServiceOrder } from '../types';
import { serviceOrderPdfStyle as styles } from '../style/style';
import { ClientAndVehicleInformation } from './ClientAndVehicleInformation';
import qrcodeYoutube from '../../../assets/qrcode-youtube-os.png';
import qrcodeApp from '../../../assets/qrcode-one-monitora-app.png';
import qrcodeWhatsapp from '../../../assets/qrcode-atendimento-ao-cliente.png';
import qrcodeLiderApp from '../../../assets/qrcode-lider-app.jpeg';
import qrcodeFidalgoApp from '../../../assets/qrcode-fidalgo-app.jpeg';
import qrcodeCarLiderApp from '../../../assets/qrcode-carlider.jpeg';
import qrcodeYoutubeCarLiderApp from '../../../assets/qrcode-youtube-carlider.jpeg';

const InstallationContractPdf: React.FC<IContractPdfPropsServiceOrder> = ({
  vehicle,
  client,
  serviceOrder,
}) => {
  // 141 - CARLIDER

  // 146  -LIDER

  // 147 - Fidalgo
  const handleLogo = () => {
    if (client?.id === 141) {
      return qrcodeCarLiderApp;
    }
    if (client?.id === 146) {
      return qrcodeLiderApp;
    }
    if (client?.id === 147) {
      return qrcodeFidalgoApp;
    }
    return qrcodeApp;
  };

  const handleYoutube = () => {
    if (client?.id === 141) {
      return qrcodeYoutubeCarLiderApp;
    }
    return qrcodeYoutube;
  };
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.table}>
          {/* Cabeçalho */}
          <View style={styles.section}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, { width: '35%' }]}>
                <Text
                  style={[
                    styles.tableCell,
                    styles.grayBackground,
                    styles.boldText,
                  ]}
                >
                  ORDEM DE SERVIÇO
                </Text>
              </View>
              <View style={[styles.tableCol, { width: '35%' }]}>
                <Text style={styles.tableCell}>Manutenção ( )</Text>
              </View>
              <View style={[styles.tableCol, { width: '30%' }]}>
                <Text style={styles.tableCell}>Instalação (X)</Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  { width: '25%', ...styles.noBorderRight },
                ]}
              >
                <Image
                  src=".../src/assets/one-logo-os.png"
                  style={styles.logo}
                />
                <Text style={styles.tableCell}>ONE MONITORA</Text>
                <Text style={styles.tableCell}>TEL: 3900-8533</Text>
              </View>
            </View>
          </View>

          {/* Informações do Cliente e Veículo */}
          <ClientAndVehicleInformation client={client} vehicle={vehicle} />

          {/* Instruções de Acesso */}
          <View style={styles.section}>
            <Text style={[styles.tableCell, styles.grayBackground]}>
              Para acessar o monitoramento do seu veículo, siga o passo a passo
              abaixo, com a leitura dos QR Codes:
            </Text>
          </View>

          {/* Caixas Superiores (5 caixas) */}
          <View style={styles.tableRow}>
            {/* Caixa 1: Dados de Acesso */}
            <View style={[styles.tableCol, { width: '20%' }]}>
              <View
                style={[
                  styles.grayBackground,
                  { height: 45, justifyContent: 'center' },
                ]}
              >
                {' '}
                {/* Ajuste a altura para 3 linhas e centralize verticalmente */}
                <Text
                  style={[
                    styles.tableCell,
                    styles.smallText,
                    styles.centeredText,
                  ]}
                >
                  {' '}
                  {/* Use styles.centeredText para centralizar o texto */}
                  SEUS DADOS DE ACESSO AO MONITORAMENTO:
                </Text>
              </View>
            </View>

            {/* Caixa 2: Passo 1 */}
            <View style={[styles.tableCol, { width: '20%' }]}>
              <View
                style={[
                  styles.grayBackground,
                  { height: 45, justifyContent: 'center' },
                ]}
              >
                {' '}
                {/* Ajuste a altura para 3 linhas e centralize verticalmente */}
                <Text
                  style={[
                    styles.tableCell,
                    styles.smallText,
                    styles.centeredText,
                  ]}
                >
                  {' '}
                  {/* Use styles.centeredText para centralizar o texto */}
                  PASSO 1:{'\n'}ASSISTA O TUTORIAL DE COMO ACESSAR O APP
                </Text>
              </View>
            </View>

            {/* Caixa 3: Passo 2 */}
            <View style={[styles.tableCol, { width: '20%' }]}>
              <View
                style={[
                  styles.grayBackground,
                  { height: 45, justifyContent: 'center' },
                ]}
              >
                {' '}
                {/* Ajuste a altura para 3 linhas e centralize verticalmente */}
                <Text
                  style={[
                    styles.tableCell,
                    styles.smallText,
                    styles.centeredText,
                  ]}
                >
                  {' '}
                  {/* Use styles.centeredText para centralizar o texto */}
                  PASSO 2:{'\n'}ACESSE O WEB APP COM OS SEUS DADOS DE{'\n'}
                  ACESSO
                </Text>
              </View>
            </View>

            {/* Caixa 4: Em caso de dúvida */}
            <View style={[styles.tableCol, { width: '20%' }]}>
              <View
                style={[
                  styles.grayBackground,
                  { height: 45, justifyContent: 'center' },
                ]}
              >
                {' '}
                {/* Ajuste a altura para 3 linhas e centralize verticalmente */}
                <Text
                  style={[
                    styles.tableCell,
                    styles.smallText,
                    styles.centeredText,
                  ]}
                >
                  {' '}
                  {/* Use styles.centeredText para centralizar o texto */}
                  EM CASO DE DÚVIDA:{'\n'}ENTRE EM CONTATO COM O NOSSO
                  ATENDIMENTO AO CLIENTE
                </Text>
              </View>
            </View>

            {/* Caixa 5: Funcionamento */}
            <View
              style={[
                styles.tableCol,
                { width: '20%', ...styles.noBorderRight },
              ]}
            >
              <View
                style={[
                  styles.grayBackground,
                  { height: 45, justifyContent: 'center' },
                ]}
              >
                {' '}
                {/* Ajuste a altura para 3 linhas e centralize verticalmente */}
                <Text
                  style={[
                    styles.tableCell,
                    styles.smallText,
                    styles.centeredText,
                  ]}
                >
                  {' '}
                  {/* Use styles.centeredText para centralizar o texto */}
                  FUNCIONAMENTO {'\n'}ATENDIMENTO {'\n'}ADMINISTRATIVO:
                </Text>
              </View>
            </View>
          </View>

          {/* Caixas Inferiores (5 caixas) */}
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, { width: '20%' }]}>
              <Text
                style={[
                  styles.centeredText,
                  styles.boldText,
                  { marginBottom: 5 },
                ]}
              >
                Login:{'\n'}
                _________
              </Text>
              <Text
                style={[
                  styles.centeredText,
                  styles.boldText,
                  { marginBottom: 5 },
                ]}
              >
                Senha:{'\n'}
                one123
              </Text>
            </View>

            {/* Caixa 2: QR Code (Vídeo no YouTube) */}
            <View
              style={[
                styles.tableCol,
                {
                  width: '20%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              ]}
            >
              <Image src={() => handleYoutube()} style={styles.image} />
            </View>

            {/* Caixa 3: QR Code (Acesso para o App) */}
            <View
              style={[
                styles.tableCol,
                {
                  width: '20%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              ]}
            >
              <Image src={() => handleLogo()} style={styles.image} />
            </View>

            {/* Caixa 4: QR Code (WhatsApp Atendimento) */}
            <View
              style={[
                styles.tableCol,
                {
                  width: '20%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              ]}
            >
              <Image src={qrcodeWhatsapp} style={styles.image} />
            </View>

            {/* Caixa 5: Horário de Atendimento */}
            <View
              style={[
                styles.tableCol,
                { width: '20%', ...styles.noBorderRight },
              ]}
            >
              <Text style={[styles.centeredText, { marginBottom: 5 }]}>
                Segunda-Quinta:{'\n'}08:00-18:00
              </Text>
              <Text style={styles.centeredText}>
                Sexta-feira:{'\n'} 08:00-17:00
              </Text>
            </View>
          </View>

          {/* Central 24H para Roubos e Furtos */}
          <View style={styles.section}>
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCol,
                  { width: '100%', ...styles.noBorderRight },
                ]}
              >
                <Text style={[styles.tableCell, styles.grayBackground]}>
                  CENTRAL 24 HORAS PARA ROUBOS E FURTOS
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    styles.grayBackground,
                    styles.boldText,
                  ]}
                >
                  LIGUE: 0800 080 1110 OPÇÃO 1
                </Text>
              </View>
            </View>
          </View>

          {/* Local Atendimento e Técnico */}
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, { width: '50%' }]}>
              <Text style={styles.tableCellLeft}>
                Local Atendimento: {serviceOrder?.serviceLocation}
              </Text>
            </View>
            <View
              style={[
                styles.tableCol,
                {
                  width: '25%',
                },
              ]}
            >
              <Text style={styles.tableCellLeft}>
                Técnico: {serviceOrder?.installer}
              </Text>
            </View>
            <View
              style={[
                styles.tableCol,
                { width: '25%', ...styles.noBorderRight },
              ]}
            >
              <Text style={styles.tableCellLeft}>
                ID/IMEI: {vehicle?.device?.imei}
              </Text>
            </View>
          </View>

          {/* Acessórios e Status */}
          <View style={styles.section}>
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCol,
                  { width: '100%', ...styles.noBorderRight },
                ]}
              >
                <Text style={[styles.tableCell, styles.grayBackground]}>
                  ACESSÓRIOS E STATUS
                </Text>
              </View>
            </View>
            {[
              [
                'Alarme',
                'OK ( ) DF ( )',
                'Chave Comando de Setas',
                'OK ( ) DF ( )',
                'Pisca Alerta',
                'OK ( ) DF ( )',
                'Computador de Bordo',
                'OK ( ) DF ( )',
              ],
              [
                'Vidro Elétrico',
                'OK ( ) DF ( )',
                'Ar Condicionado',
                'OK ( ) DF ( )',
                'Som/Rádio/CD/DVD',
                'OK ( ) DF ( )',
                'Luz do Sistema de Freio ABS',
                'OK ( ) DF ( )',
              ],
              [
                'Trava Elétrica',
                'OK ( ) DF ( )',
                'Ar Quente / Ar Frio',
                'OK ( ) DF ( )',
                'Comando do Limpador de Para-brisa',
                'OK ( ) DF ( )',
                'Setas Direita / Esquerda',
                'OK ( ) DF ( )',
              ],
              [
                'Retrovisor Elétrico / Manual',
                'OK ( ) DF ( )',
                'Lanternas Dianteiras e Traseiras',
                'OK ( ) DF ( )',
                'Luz de Cortesia e Porta Malas',
                'OK ( ) DF ( )',
                'Luz de Freio / Brake Light',
                'OK ( ) DF ( )',
              ],
              [
                'Tampa de Porta Luvas',
                'OK ( ) DF ( )',
                'Farol Baixo Esquerdo / Direito',
                'OK ( ) DF ( )',
                'Desembaçador Traseiro / Espelhos',
                'OK ( ) DF ( )',
                'Luz do Sistema de Air Bag',
                'OK ( ) DF ( )',
              ],
              [
                'Teto Solar Elétrico/Manual',
                'OK ( ) DF ( )',
                'Farol Alto Esquerdo / Direito',
                'OK ( ) DF ( )',
                'Limpador de Para-brisa Diant./Tras./Faróis',
                'OK ( ) DF ( )',
                'Luzes de Anomalia / Iluminação do Painel',
                'OK ( ) DF ( )',
              ],
              [
                'Buzina / Sirene',
                'OK ( ) DF ( )',
                'Luz de Ré',
                'OK ( ) DF ( )',
                'Freio de Estacionamento',
                'OK ( ) DF ( )',
                'Outros',
                'OK ( ) DF ( )',
              ],
            ].map((row, index) => (
              <View style={styles.tableRow} key={index}>
                {row.map((cell, cellIndex) => (
                  <View
                    style={[
                      styles.tableCol,
                      { flex: cellIndex % 2 === 0 ? 2 : 1.5 }, // Aumentar largura das colunas de status
                      cellIndex === row.length - 1 ? styles.noBorderRight : {},
                    ]}
                    key={cellIndex}
                  >
                    <Text style={styles.tableCell}>{cell}</Text>
                  </View>
                ))}
              </View>
            ))}
          </View>

          {/* Observações Finais */}
          <View style={styles.section}>
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCol,
                  { width: '100%', ...styles.noBorderRight },
                ]}
              >
                <Text style={styles.tableCellLeft}>OBSERVAÇÃO:</Text>
                <Text style={styles.tableCellLeft}>{'\n'.repeat(5)}</Text>{' '}
                {/* 5 linhas em branco */}
              </View>
            </View>
          </View>

          {/* Declaração e Assinatura */}
          <View style={styles.section}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, { width: '50%' }]}>
                <Text style={styles.tableCellLeft}>
                  Nome do Responsável / CPF
                </Text>
                <View style={styles.signatureLine} />
              </View>
              <View
                style={[
                  styles.tableCol,
                  { width: '50%', ...styles.noBorderRight },
                ]}
              >
                <Text style={styles.tableCellLeft}>
                  Assinatura do Responsável
                </Text>
                <View style={styles.signatureLine} />
              </View>
            </View>
          </View>

          <View style={styles.section}>
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCol,
                  { width: '100%', ...styles.noBorderRight },
                ]}
              >
                <Text style={[styles.tableCellLeft, styles.grayBackground]}>
                  Declaro estar ciente do serviço realizado no veículo e que em
                  caso de dúvida fui orientado a ligar para a Central da
                  Prestadora de Serviços. Acompanhei a vistoria feita antes e
                  depois da execução do serviço estando em pleno acordo que não
                  há qualquer problema com o veículo. Estou de acordo com as
                  informações assinaladas e descritas neste formulário, não
                  tendo nada a reclamar.
                </Text>
              </View>
            </View>
          </View>

          {/* Local e Data / Assinatura de Conclusão */}
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, { width: '50%' }]}>
              <Text style={styles.tableCellLeft}>Local e Data:</Text>
              <View style={styles.fullWidthLine} />
            </View>
            <View
              style={[
                styles.tableCol,
                { width: '50%', ...styles.noBorderRight },
              ]}
            >
              <Text style={styles.tableCellLeft}>Assinatura de Conclusão:</Text>
              <View style={styles.fullWidthLine} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default InstallationContractPdf;
