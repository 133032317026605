import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { masks } from '../../utils/mask';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiInputBase-root': {
      fontSize: theme.typography.fontSize,
    },
    '& .MuiFilledInput-inputMarginDense': {
      paddingTop: '19px',
      paddingBottom: '5px',
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense': {
      transform: 'translate(12px, 7px) scale(0.75)',
    },
    '& .MuiInputLabel-filled.MuiInputLabel-marginDense': {
      transform: 'translate(12px, 14px) scale(1)',
    },
    '& .MuiFilledInput-root': {
      borderRadius: '4px',
    },
    '& .MuiFormLabel-root': {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fontSize: theme.typography.labelFontSize,
    },
    '& .MuiInputLabel-filled': {
      zIndex: 0,
    },
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: '1rem',
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingTop: '12px',
      paddingLeft: '16px',
    },
    '& .MuiFilledInput-underline:before, .MuiFilledInput-underline:after ': {
      border: 'none',
    },
  },
  select: {
    '& .MuiMenuItem-root': {
      fontSize: '0.6rem',
    },
  },
}));

type Props = {
  variant: string;
  handleChange?: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  onChange?: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  mask?: string; // * Improve typing after
  disabled?: boolean;
} & TextFieldProps;

export default function Input({
  handleChange,
  onChange,
  mask,
  children,
  variant = 'outlined',
  disabled = false,
  ...props
}: Props) {
  const classes = useStyles();

  return (
    <TextField
      fullWidth
      variant={variant}
      size="small"
      margin="none"
      onChange={e => {
        if (mask) {
          if (masks[mask as keyof typeof masks]) {
            masks[mask as keyof typeof masks](e);
          }
        }
        if (onChange) onChange(e);
        if (handleChange) handleChange(e);
      }}
      disabled={disabled}
      {...props}
      className={classes.root}
      SelectProps={{
        MenuProps: {
          className: classes.select,
        },
      }}
    >
      {children}
    </TextField>
  );
}
