import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: 'https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Me5Q.ttf' },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfBBc9.ttf',
      fontWeight: 'bold',
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20,
    backgroundColor: '#f5f5f5',
  },
  logo: {
    width: 50,
    height: 20,
    marginBottom: 5,
    alignSelf: 'center',
  },
  section: {
    marginBottom: 20,
    padding: 15,
    borderRadius: 8,
    backgroundColor: '#ffffff',
    border: '1px solid #dddddd',
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    color: '#000',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  subtitle: {
    fontSize: 10,
    textAlign: 'center',
    color: '#000',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 8,
    color: '#000',
    marginBottom: 2,
    fontFamily: 'Roboto',
  },
  link: {
    fontSize: 12,
    color: '#007BFF',
    marginBottom: 5,
    paddingLeft: 15,
  },

  boldText: {
    fontSize: 8,
    color: '#000',
    marginBottom: 2,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },

  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  column: {
    width: '45%',
  },
  commentBox: {
    backgroundColor: '#f9f9f9',
    padding: 10,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#dddddd',
    borderRadius: 8,
  },
});

interface IProps {
  responsableData: {
    id: number;
    name: string;
    street: string;
    cpfCnpj: string;
    ieRg: string;
    neighborhood: string;
    city: string;
    zipCode: string;
    clientType: string;
    email: string;
    phone: string;
    phone2: string;
    phone3: string;
    phone4: string;
  };
  vehicleData: {
    id: number;
    clientId: number;
    plate: string;
    make: string;
    model: string;
    color: string;
    type: string;
    vin: string;
    renavam: string;
    modelYear: string;
    manufacturedYear: string;
  };
}

export const ServiceOrderInstalationContractPDF = ({
  responsableData,
  vehicleData,
}: IProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image src="/image/one_logo_full.png" style={styles.logo} />
        <Text style={{ ...styles.title, textAlign: 'left' }}>FORMULÁRIO</Text>
        <View
          style={{
            marginTop: '20px',
            borderTop: '2px solid #000',
            borderBottom: '2px solid #000',
          }}
        >
          <Text
            style={{ ...styles.subtitle, textAlign: 'left', marginTop: '5px' }}
          >
            Dados do responsável:
          </Text>
          <View
            style={{
              marginTop: '5px',
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              justifyContent: 'space-between',
              width: '90%',
            }}
          >
            <View
              style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '250px' }}
            >
              <Text style={styles.text}>Nome: {responsableData.name}</Text>
              <Text style={styles.text}>
                CPF/CNPJ: {responsableData.cpfCnpj}
              </Text>
              <Text style={styles.text}>
                Endereço: {responsableData.street}
              </Text>
              <Text style={styles.text}>
                Telefones: {responsableData.phone} {responsableData.phone2}
                {responsableData.phone3} {responsableData.phone4}
              </Text>
              <Text style={styles.text}>E-mail: {responsableData.email}</Text>
            </View>
            <View style={{ display: 'flex' }}>
              <Text style={styles.text}>
                RG:
                {responsableData.clientType !== 'F' ? '' : responsableData.ieRg}
              </Text>
              <Text style={styles.text}>
                Bairro: {responsableData.neighborhood}
              </Text>
              <Text style={styles.text}>Cidade: {responsableData.city}</Text>
            </View>
            <View style={{ display: 'flex' }}>
              <Text style={styles.text}>
                Tipos de pessoa: (
                {responsableData.clientType === 'F' ? 'X' : ''}) Física (
                {responsableData.clientType !== 'F' ? 'X' : ''}) Jurídica
              </Text>
              <Text style={styles.text}>
                Inscrições estaduais:
                {responsableData.clientType === 'F' ? '' : responsableData.ieRg}
              </Text>
              <Text style={styles.text}>CEP: {responsableData.zipCode}</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            marginTop: '5px',
            borderBottom: '2px solid #000',
          }}
        >
          <Text style={{ ...styles.subtitle, textAlign: 'left' }}>
            Dados dos veículos:
          </Text>
          <View
            style={{
              marginTop: '5px',
              display: 'flex',
              gap: '1px',
            }}
          >
            <View
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                width: '80%',
              }}
            >
              <Text style={styles.text}>Marca: {vehicleData.make}</Text>
              <Text style={styles.text}>
                Modelo: {vehicleData?.make} - {vehicleData?.model}
              </Text>
              <Text style={styles.text}>Ano: {vehicleData.modelYear}</Text>
              <Text style={styles.text}>Placa: {vehicleData.plate}</Text>
            </View>
            <View
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                width: '80%',
              }}
            >
              <Text style={styles.text}>Cor: {vehicleData.color}</Text>
              <Text style={styles.text}>Chassis: {vehicleData.vin}</Text>
              <Text style={styles.text}>Renavam: {vehicleData.renavam}</Text>
            </View>
            <View
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                width: '80%',
              }}
            >
              <Text style={styles.text}>
                Categoria: ({vehicleData.type === 'car' ? 'X' : ''}) Automóvel (
                {vehicleData.type === 'truck' ? 'X' : ''}) Caminhão (
                {vehicleData.type === 'motorcycle' ? 'X' : ''}) Motocicleta (
                {vehicleData.type === 'boat' ? 'X' : ''}) Barco
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            marginTop: '5px',
            gap: '10px',
            textAlign: 'left',
            display: 'flex',
          }}
        >
          <View
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '-5px',
            }}
          >
            <Text style={{ ...styles.subtitle, textAlign: 'center' }}>
              CONTRATO PARTICULAR DE RESPONSABILIDADEDE EQUIPAMENTO.
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <View
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <Text style={styles.text}>
                <Text style={styles.subtitle}>ONE MONITORAMENTO LTDA</Text>,
                pessoa jurídica de direito privado, inscrita no CNPJ
                26.491.952/0001-49, com sede na cidade de Rio de Janeiro, estado
                do Rio de Janeiro, a Rua General Olímpio, 340 CEP: 23515-127
                doravante denominada CONTRATADA e de outro lado o adquirente
                conforme qualificação preenchida no FORMULARIO em anexo a este
                documento, doravante denominado simplesmente RESPONSÁVEL, tem
                entre si justo e contratado o que segue, nos termos e nas
                condições abaixo:
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <Text style={styles.subtitle}>CONSIDERAÇÕES:</Text>
              <Text style={styles.text}>
                Que a CONTRATADA é uma empresa especializada na comercialização
                e na prestação dos serviços de assistência ao consumidor de
                sistema eletrônico micro processados, conforme descrito na
                cláusula primeira “definições”;
              </Text>
              <Text style={styles.text}>
                Que a CONTRATADA está apta, técnica e logisticamente, para
                atender a CONTRATANTE em todo o território nacional ressalvado
                as áreas de sombra abaixo definidas;
              </Text>
              <Text style={styles.text}>
                Que a CONTRATADA está apta, técnica e logisticamente, para
                atender a CONTRATANTE em todo o território nacional ressalvado
                as áreas de sombra abaixo definidas;
              </Text>
              <Text style={styles.text}>
                Que a CONTRATANTE tem pleno conhecimento dos equipamentos objeto
                deste contrato, ficando ciente de seu funcionamento e que a sua
                eficácia dependerá de sua atuação, área de cobertura das
                operadoras de telefonia celular e procedimentos corretos;
              </Text>
              <Text style={styles.text}>
                Que a CONTRATANTE tem pleno conhecimento de que o sistema pode
                sofrer interferências ou sombras, dependendo da localização em
                que estiver o veículo e ou da área de cobertura das operadoras
                de telefonia celular.
              </Text>
            </View>
            <View>
              <Text style={styles.subtitle}>CLÁUSULA PRIMEIRA: DEFINIÇÕES</Text>
              <Text style={styles.text}>
                GPS: Sistema de Posicionamento Global
              </Text>
              <Text style={styles.text}>
                GSM/GPRS: Sistema Global para Comunicação Móvel
              </Text>
              <Text style={styles.text}>
                RASTREADOR GSM: conjunto de equipamentos eletrônicos micro
                processados, com capacidade de interagir com fornecedores das
                tecnologias GPS, GSM/GPRS, para compilação de dados de
                localização e despotencialização remota do veículo.
              </Text>
              <Text style={styles.text}>
                ÁREAS DE SOMBRA: Áreas que, por razões físicas ou
                eletromagnéticas, não podem ser alcançadas pelos sinais
                GPS/GSM/GPRS, tais como, interiores de túneis, garagens
                subterrâneas, proximidades a morros, serras, topografias
                diversas, áreas fechadas em geral, dentre outros.
              </Text>
              <Text style={styles.text}>
                FORMULARIO: documento com dados do RESPONSÁVEL anexo a este
                instrumento.
              </Text>

              <Text style={styles.subtitle}>CLÁUSULA PRIMEIRA - OBJETO</Text>

              <Text style={styles.text}>
                O presente contrato tem por objeto a RESPONSABILIDADE do
                equipamento eletrônico e a prestação de serviços de
                monitoramento, bem como a despotencialização de veículo
                automotor à distância, com sinal codificado, por meio de
                telefonia móvel celular.
              </Text>

              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Primeiro:
                </Text>
                O equipamento instalado tem como objetivo a coleta de dados de
                localização geográfica do veículo automotor no qual se encontra
                instalado o equipamento Rastreador GSM, através do sistema de
                transmissão de dados disponível e em uso pela operadora de
                telefonia celular prestadora do serviço.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Segundo:
                </Text>
                A CONTRATADA contratara os serviços de uma operadora de
                telefonia celular, bem como a tecnologia a ser utilizada, para
                que esta proceda ao envio de comandos/sinais GSM/GPRS,
                destinados à ativação do sistema de despotencialização
                eletrônico/remoto veicular presente no rastreador.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Terceiro:
                </Text>
                Os dados de localização do veículo equipado com o Rastreador
                serão disponibilizados ao RESPONSÁVEL, através de página
                eletrônica veiculada na internet que deverá ser acessada
                diretamente pelo mesmo. Todas as comunicações ficarão
                registradas através de meio eletrônico junto à CONTRATADA.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Quarto:
                </Text>
                Os serviços acima descritos dependem das seguintes condições,
                devendo ser observadas as definições da cláusula primeira:
              </Text>
              <Text style={styles.text}>
                I) Que o veículo se encontre dentro da área tecnicamente coberta
                pelos serviços da operadora de telefonia celular;
              </Text>
              <Text style={styles.text}>
                II) Que o veículo esteja com seu sistema elétrico em perfeito
                estado de funcionamento;
              </Text>
              <Text style={styles.text}>
                III) Que o veículo não se encontre em ÁREAS DE SOMBRA;
              </Text>
              <Text style={styles.text}>
                IV) Que o veículo não sofra avarias que afetem seu sistema
                elétrico e que possam, ainda que indiretamente, danificar o
                equipamento;
              </Text>
              <Text style={styles.text}>
                V) Que o Rastreador instalado no veículo não seja removido,
                desinstalado ou até mesmo testado por pessoas não credenciadas e
                ou não autorizadas pela CONTRATADA.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Quinto:
                </Text>
                No caso de despotencialização do veículo em movimento, uma vez
                acionado pelo RESPONSÁVEL, cabe à CONTRATADA avaliar o melhor
                momento de fazê-lo, atendendo à legislação de trânsito vigente à
                época bem como se houver refém no interior do veículo, não sendo
                obrigação sua proceder ao bloqueio de imediato, por motivo de
                segurança.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Sexto:
                </Text>
                A visualização da localização do veículo via internet terá o seu
                bom desempenho condicionado à qualidade e compatibilidade do
                sistema de informática utilizado pelo RESPONSÁVEL.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Sétimo:
                </Text>
                O RESPONSÁVEL declara expressamente ter sido esclarecido:
              </Text>
              <Text style={styles.text}>
                a) Que o procedimento básico exige que o RESPONSÁVEL deva
                informar a central de monitoramento, a autoridade policial
                competente, bem como a seguradora sobre o sinistro ocorrido.
              </Text>
              <Text style={styles.text}>
                b) Que o Rastreador GSM não receberá os comandos enviados pela
                operadora de telecomunicação na hipótese de mau funcionamento do
                sistema elétrico/eletrônico do veículo em que estiver instalado.
              </Text>
              <Text style={styles.text}>
                c) Que a CONTRATADA não se responsabiliza por perdas, danos e
                lucros cessantes decorrentes de eventual sinistro, tampouco por
                perdas, danos e lucros cessantes decorrentes do eventual não
                recebimento da radiocomunicação da tecnologia celular empregada
                pelo monitoramento, e ainda, por perdas, danos e lucros
                cessantes causados por quaisquer motivos decorrentes do sinal
                oriundo do equipamento instalado no veículo.
              </Text>
              <Text style={styles.text}>
                d) Que a CONTRATADA não garante nem se responsabiliza de
                qualquer forma ou meio pelo estado de conservação do veículo
                equipado com o equipamento, tampouco pela integridade física de
                seus ocupantes, ou de eventos que envolvam terceiros.
              </Text>
              <Text style={styles.text}>
                e) Que todas as funcionalidades do produto dependem da
                confirmação do usuário, mediante senha ou confirmação de dados
                cadastrais.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Oitavo:
                </Text>
                Este contrato não tem caráter de apólice de seguro e a prestação
                dos serviços de monitoramento e despotencialização ora ajustada
                entre as partes não impede a ocorrência de algum sinistro com o
                veículo do RESPONSÁVEL e não substitui qualquer outro tipo de
                equipamento ou dispositivo antifurto, razão pela qual a
                CONTRATADA não é responsável por qualquer prejuízo sofrido pelo
                RESPONSÁVEL em caso de furto/roubo do referido veículo ou de
                bens encontrados no interior deste.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Nono:
                </Text>
                O RESPONSÁVEL está ciente de que o equipamento opera por sistema
                de telefonia móvel celular e que o seu desempenho está sujeito
                às condições de recepção dos sinais de telefonia móvel celular,
                os quais podem sofrer interferências que impeçam o regular
                funcionamento do equipamento.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Décimo:
                </Text>
                Em função do disposto no item anterior, não cabe à CONTRATADA
                suportar qualquer responsabilidade por problemas na operação do
                equipamento ocorridos por falhas na rede pública de
                telecomunicações, em virtude de regiões de sombra para o sinal
                de rádio frequência ou indisponibilidade, momentânea ou
                definitiva, dos serviços de telefonia móvel celular.
              </Text>
              <Text style={{ ...styles.boldText, textDecoration: 'underline' }}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Décimo Primeiro:
                </Text>
                O RESPONSÁVEL se obriga, em caso de constatação de defeito no
                equipamento, a informar imediatamente a CONTRATADA com a
                finalidade de ser indicado um técnico especializado e
                credenciado para proceder à verificação e o conserto do
                equipamento, não sendo admitida a colocação em conserto em
                oficina não credenciada.
              </Text>
              <Text style={{ ...styles.boldText, textDecoration: 'underline' }}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Décimo Segundo:
                </Text>
                Em caso de ocorrência de defeito no sistema elétrico do veículo,
                a CONTRATANTE devera igualmente informar o RESPONSÁVEL e
                providenciar a correção do defeito para correto funcionamento do
                rastreador GSM.
              </Text>
              <Text style={{ ...styles.boldText, textDecoration: 'underline' }}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Décimo Terceiro:
                </Text>
                O RESPONSÁVEL obriga-se a conservar os equipamentos em perfeitas
                condições de uso, não permitindo que terceiras pessoas venham a
                manusear suas instalações internas, acarretando avarias e
                prejudicando seu bom funcionamento.
              </Text>
            </View>
            <View style={{ marginTop: '10px' }}>
              <Text style={styles.subtitle}>
                CLÁUSULA SEGUNDA– RESPONSABILIDADE DO EQUIPAMENTO
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Primeiro:
                </Text>
                Após extinto o contrato, em alguma das hipóteses previstas neste
                instrumento, o RESPONSÁVEL obriga-se a devolver o equipamento á
                CONTRATADA em até 2 (dois) dias úteis seguintes ao da extinção,
                após o prazo correrá COBRANÇA da mensalidade pelos serviços de
                monitoramento, por dia de monitoramento no cumprimento dessa
                obrigação, além de se considerar apropriação indébita em caso de
                retenção indevida.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Segundo:
                </Text>
                O presente contrato, assim como os equipamentos a que se refere,
                não poderá ser cedido ou transferido a terceiros, mesmo em caso
                de venda do veículo, obrigando o RESPONSÁVEL a comunicar a
                CONTRATADA sua ocorrência, para que seja procedida a retirada do
                equipamento que deverá ser feita, obrigatoriamente, em oficina
                credenciada, sob pena de responder pelos danos a que der causa.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Terceiro:
                </Text>
                Igualmente, em caso de troca de veículo pelo RESPONSÁVEL,
                obriga-se este a comunicar o fato a CONTRATADA para que possam
                ser alteradas todas as informações registradas e a transferência
                do equipamento.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Quarto:
                </Text>
                Findo o contrato ou não sendo ele prorrogado, obriga-se o
                RESPONSÁVEL a devolver o equipamento, mediante sua retirada em
                oficina credenciada indicada pela CONTRATADA, mediante prévia
                comunicação, sob pena de se considerar como infração ao
                contrato, sujeito o RESPONSÁVEL às penalidades estabelecidas.
              </Text>
              <Text style={{ ...styles.boldText, textDecoration: 'underline' }}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Quinto:
                </Text>
                À infração a qualquer das cláusulas deste contrato acarretará a
                multa, calculado sobre o valor vigente do serviço de
                monitoramento e será cobrado pela via executiva, considerada
                como dívida líquida e certa.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Sexto:
                </Text>
                Pela execução do contrato, a RESPONSÁVEL pagará à CONTRATADA o
                equipamento locado. Caso não haja devolução do equipamento.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Sétimo:
                </Text>
                O RESPONSÁVEL autoriza desde logo a CONTRATADA a emitir
                duplicata no valor do equipamento para a cobrança dos valores
                decorrentes do presente contrato, podendo proceder ao
                apontamento desses títulos a protesto por falta de pagamento,
                independentemente de aceite, cuja falta é suprida pela presente
                autorização.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Oitavo:
                </Text>
                As instalações e manutenções do equipamento são realizadas em
                território nacional, as retiradas deverão ser realizadas na
                mesma cidade da instalação. Caso o veículo seja levado para
                outro estado/cidade, impossibilitando a retirada do equipamento
                por técnicos credenciados da CONTRATADA , será realizada a
                cobrança pelo valor do equipamento.
              </Text>
            </View>
            <View style={{ marginTop: '10px' }}>
              <Text style={styles.subtitle}>
                CLÁUSULA TERCEIRA - REGRAS GERAIS DA EXECUÇÃO DO CONTRATO
              </Text>
              <Text style={styles.text}>
                Somente o RESPONSÁVEL poderá solicitar à Central de Atendimento
                da CONTRATADA despotencialização, a ativação e a desativação do
                equipamento, ficando a CONTRATADA expressamente autorizada a
                deixar de atender solicitações feitas por terceiros sem
                autorização.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Primeiro:
                </Text>
                Comunicada a ocorrência de sinistro do veículo pelo RESPONSÁVEL,
                fica a CONTRATADA, desde já, autorizada a proceder na tentativa
                da sua localização e despotencialização.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Segundo:
                </Text>
                O RESPONSÁVEL é responsável pela verificação periódica do
                correto funcionamento do equipamento para o fim de identificar e
                informar à CONTRATADAS eventuais problemas técnicos,
                preventivamente.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Terceiro:
                </Text>
                Para o fim do disposto no parágrafo anterior o RESPONSÁVEL
                deverá periodicamente acessar o sistema através da página
                disponibilizada pela CONTRATADA na internet e certificar-se do
                bom funcionamento do sistema, caso identifique alguma anomalia
                no sistema como falta de posição e atualização do veículo no
                momento do teste, informar de imediato a CONTRATADA para solução
                do problema.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Quarto:
                </Text>
                Ressalvada a hipótese de teste, referida no item anterior, a
                solicitação de acionamento do equipamento somente deverá ser
                feita em caso de ocorrência de sinistro no veículo.
              </Text>
              <Text
                style={{
                  ...styles.boldText,
                  fontSize: '9px',
                  textDecoration: 'underline',
                }}
              >
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Quinto
                </Text>
                O RESPONSÁVEL fica responsável pela conservação do equipamento,
                comprometendo-se a não permitir que pessoa não autorizada pela
                CONTRATADA realize qualquer espécie de intervenção técnica no
                mesmo.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Sexto:
                </Text>
                A assistência técnica somente será realizada por pessoal
                autorizado pela CONTRATADA.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Sétimo:
                </Text>
                Caso o técnico autorizado pela CONTRATADA se desloque até o
                local indicado para a instalação, retirada ou assistência
                técnica do equipamento e o veículo não esteja disponível, será
                cobrada a taxa pela visita técnica constante na tabela de
                serviços da CONTRATADA.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Oitavo:
                </Text>
                Em caso de sinistro, uma vez determinadas as coordenadas de
                localização do veículo pelo equipamento, através do rastreador
                GSM, a CONTRATADA, através de equipe terceirizada, certificará o
                local da suposta localização do veículo e informará a
                RESPONSÁVEL caso encontrado o veículo, os quais atuarão com base
                na informação de localização prestada pela Central de
                Atendimento da CONTRATADA, desde que o veículo se encontre em
                local coberto por sinais de telefonia celular e pelo serviço de
                auxílio ao resgate.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Nono:
                </Text>
                O auxílio ao resgate, constante no parágrafo anterior, não
                garante a restituição do veículo o RESPONSÁVEL, nem gera tal
                obrigação contratual à CONTRATADA, mas apenas serve de apoio às
                autoridades policiais competentes, as quais continuam sendo as
                únicas responsáveis pela recuperação do veículo.
              </Text>
            </View>
            <View style={{ marginTop: '10px' }}>
              <Text style={styles.subtitle}>
                CLÁUSULA QUARTA – RESCISÃO E PENALIDADE
              </Text>
              <Text style={styles.text}>
                Qualquer das partes poderá dar por rescindido automaticamente
                este contrato mediante notificação ou interpelação judicial ou
                extrajudicial, na hipótese da outra parte não cumprir qualquer
                das obrigações assumidas neste contrato, sem prejuízo da
                cobrança de eventuais encargos e multas contratuais devidos;
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Primeiro:
                </Text>
                A parte que descumprir qualquer clausula ou condições pactuadas,
                além de facultar a parte inocente à rescisão do presente
                contrato por inadimplemento contratual, ficara sujeita a multa
                contratual estabelecida no contrato, sem prejuízo das demais
                cominações previstas no contrato.
              </Text>
            </View>
            <View style={{ marginTop: '10px' }}>
              <Text style={styles.subtitle}>
                CLÁUSULA QUINTA– DAS DISPOSIÇÕES FINAIS
              </Text>
              <Text style={styles.text}>
                O RESPONSÁVEL declara ter sido devidamente instruída pela
                CONTRATADA sobre o correto funcionamento do equipamento e ter
                recebido o manual do usuário contendo todas as informações
                necessárias à sua operacionalização.
              </Text>
              <Text style={styles.text}>
                <Text style={{ ...styles.boldText, fontSize: '9px' }}>
                  Parágrafo Primeiro:
                </Text>
                Fica eleito o foro da Comarca do RESPONSÁVEL como competente
                para dirimir quaisquer dúvidas e omissões do presente contrato.
              </Text>
            </View>
            <View style={{ marginTop: '10px' }}>
              <Text style={styles.subtitle}>
                CLÁUSULA SEXTA: DA OBRIGATORIEDADE
              </Text>
              <Text style={styles.text}>
                Os direitos, vantagens, privilégios e obrigações decorrentes
                deste instrumento, obrigam não somente as partes contratantes,
                como também seus herdeiros legais, sucessores, os quais darão e
                haverão este instrumento sempre por bom, firme, valioso e isento
                de dúvidas. AS PARTES CONFIRMAM QUE O PRESENTE CONTRATO E SEUS
                ANEXOS CONSTITUEM A MANIFESTAÇÃO INTEGRAL E EXCLUSIVA DO ACORDO
                HAVIDO ENTRE AS PARTES E SUBSTITUEM E CANCELAM TODOS OS ACORDOS
                PRÉVIOS, VERBAIS E ESCRITOS, E TODAS AS COMUNICAÇÕES ENTRE AS
                PARTES NO QUE CONCERNE AO OBJETO DO PRESENTE INSTRUMENTO. E, por
                assim estarem certos, ajustados e de pleno acordo, assinam o
                presente em 02(duas) vias de igual teor e forma, que após lidas
                e achadas conforme, diante de duas testemunhas, para que surta
                seus efeitos legais e jurídicos.
              </Text>
            </View>
            <View style={{ marginTop: '10px' }}>
              <Text style={styles.text}>
                Rio de Janeiro, ___ de __________ de 2025.
              </Text>
              <View
                style={{
                  marginTop: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <View style={{ alignItems: 'flex-start' }}>
                  <Text
                    style={{
                      width: 200,
                      borderBottomWidth: 1,
                      borderBottomColor: 'black',
                      borderBottomStyle: 'solid',
                      textAlign: 'center',
                      marginBottom: '5px',
                    }}
                  />
                  <Text style={styles.text}>RESPONSÁVEL</Text>
                </View>

                <View style={{ width: 50 }} />

                <View style={{ alignItems: 'flex-end' }}>
                  <Text
                    style={{
                      width: 200,
                      borderBottomWidth: 1,
                      borderBottomColor: 'black',
                      borderBottomStyle: 'solid',
                      textAlign: 'center',
                      marginBottom: '5px',
                    }}
                  />
                  <Text style={styles.text}>CPF</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
